// Fonts
@import url('https://fonts.googleapis.com/css?family=Poppins');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

//Vue Awesome Notifications
@import '~vue-awesome-notifications/dist/styles/style';

//Vue Select
@import "vue-select/src/scss/vue-select.scss";

@import 'vue-js-modal/dist/styles.css';
